import React, { useState, useEffect, useMemo } from 'react'

import { cloneDeep } from "lodash"
import Pagination from '../../../component/Pagination/Pagination'
import { Col, Row, Dropdown } from 'react-bootstrap'
import ModalInfo from '../../../component/Modal/modalInfo/ModalInfo'
import { useSelector, useDispatch } from 'react-redux'
import { infoAction } from '../../../store/EditInfo/infoSlice'
import { FcEmptyTrash } from 'react-icons/fc'
import { FaSort } from 'react-icons/fa'
import './EditInformation.scss'

let PageSize = 10;
function EditInformation(props) {
  const [getInformation, setInformation] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [sendData, setSendData] = useState({})

  const testt = ""




  const showEdit = useSelector(state => state.info.EditInfo)
  const dispatch = useDispatch()
  useEffect(() => {
    fetch('http://65.21.30.187:3000/read_strategies')
      .then(res => {
        if (res.ok) {
          res = res.json()
          console.log(res)
          return res
        }
        throw res
      }).then(data => {
        setInformation(data)
      })

  }, [])
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return getInformation.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);




  const deleteInfo = async (idd, categoryy) => {

    try {

      const sendInfo = await fetch('http://65.21.30.187:3000/delete_strategy', {
        method: "POST",
        headers: { "Content-Type": "application/json" },

        body: JSON.stringify({
          strategy_id: idd,
          category: categoryy
        })

      })
      const resInfo = await sendInfo.json();
      console.log(resInfo)
      if (resInfo.status < 300) {
        alert("با موفقيت حذف شد")
        // <Alert varient='success' >
        //   با موفقيت حذه شد
        // </Alert>
        console.log(resInfo)

      } else {
        alert(" حذف موفقيت آميز نبود")
      }
    } catch (error) {

    }
  }

  const [sortType, setSortType] = useState({
    value: "index",
    isAscending: true,
  });
  const tableHeadItems = [
    {
      title: "آیدی",
      id: "id",
    },
    {
      title: "کانال",
      id: "channel",
    },
    {
      title: "تاریخ",
      id: "date",
    },
    {
      title: "high",
      id: "high",
    },
    {
      title: "low",
      id: "low",
    },
    {
      title: "نماد",
      id: "symbol",
    },
    {
      title: "اهداف",
      id: "goal",
    },
    {
      title: "مقدار",
      id: "price",
    },
    {
      title: "ویرایش",
      id: "edit",
    },
  ];

  const handleSortData = () => {
    
      const isAscending = sortType.isAscending;
      const sortTypes = {
        index: (a, b) => a.id - b.id,
        date: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        channel: (a, b) => a.category - b.category,
        // price: (a, b) => a.price - b.price,
      };
      
      const items = currentTableData.sort(sortTypes[sortType.value]);
      
      
      return isAscending ? items.reverse() : items;
    
  };

  return (

    <div className='EDIRINFORMATION w-100'>

      {showEdit && <ModalInfo info={sendData} />}
      <Row className="w-100 d-flex justify-content-center flex-column align-items-center my-3 position-relative">

        <Col className="position-relative" xs={10} sm={10} md={10} lg={8} xl={8} >
          <div className="overflow-x-auto bordered shadow rounded">
            <table className="information-table">
              <thead>
                <tr className='font-fa bg-gray'>
                  {tableHeadItems.map((item, index) => (
                    <th key={index}>
                      {item.id == "date" || item.id == "channel" ?
                        <button
                          style={{ backgroundColor: "transparent", border: "0" }}
                          className="w-fit d-flex flex-center mx-auto"

                          onClick={() => {
                            
                            setSortType((p) => ({
                              value: item.id,
                              isAscending: item.id === p.value ? !p.isAscending : true,
                            }));
                          }}
                        >
                          {item.title}
                          <span
                            style={{
                              transition: "250ms",
                              transform:
                                sortType.value === item.id && sortType.isAscending
                                  ? "rotateZ(180deg)"
                                  : "rotateZ(0deg)",
                            }}
                            className="bi bi-chevron-down"
                          >
                            <FaSort />
                          </span>
                        </button>
                        :
                        <button
                          style={{ backgroundColor: "transparent", border: "0" }}
                          className="w-fit d-flex flex-center mx-auto"
                        >
                          {item.title}

                        </button>
                      }

                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {handleSortData().map((item) => (
                  <tr className='font-fa text-center animated'>
                    <td className="fs-12 text-tableTd text-center">{item.id}</td>
                    <td className="fs-12 text-success text-center">{item.category}</td>
                    <td className="fs-12 text-tableTd text-center">{item.date}</td>
                    <td className="fs-12 text-tableTd text-center">{item.high}</td>
                    <td className="fs-12 text-tableTd text-center">{item.low}</td>
                    <td className="fs-12 text-info text-center">{item.symbol}</td>
                    <td className="fs-12 text-tableTd text-center">{Object.keys(item.target_prices).length}</td>
                    <td className="fs-12 text-tableTd text-center">{item.value}</td>
                    <td className=''>

                      <span onClick={() => { setSendData(item); dispatch(infoAction.changeEdit()) }} className=' my-3 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                          <path d="M1.5034 9.31636L0 13.9999L4.68367 12.4735L13.9354 3.26091L10.764 0.0900879L1.5034 9.31636Z" fill="#F0B31D" />
                        </svg>
                      </span>
                      <span onClick={() => { deleteInfo(item.id, item.category) }} className="mx-2 cursor-pointer" >
                        <FcEmptyTrash />
                      </span>
                    </td>

                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </Col>
        <Col className="my-3" xs={10} sm={10} md={10} lg={8} xl={8} >
          <div className="d-flex justify-content-end">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={getInformation.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </Col>

      </Row>
    </div>
  )
}



export default EditInformation