import {createSlice} from '@reduxjs/toolkit'

const initialState = { EditInfo : false }

export const infoSlice = createSlice({
    name : "info",
    initialState,
    reducers : {
        changeEdit(state){
            state.EditInfo = !state.EditInfo
        }
    }
})


export const infoAction = infoSlice.actions

