import React, { useState , useEffect } from 'react'

import { cloneDeep, startCase, startsWith } from "lodash"
import './ModalInfo.scss'
import { Col, Row, Dropdown, Button } from 'react-bootstrap'
import {useDispatch , useSelector} from 'react-redux'
import {infoAction} from '../../../store/EditInfo/infoSlice'
import useInput from '../../../Hooks/useInput'

function ModalInfo({
    info

}) {
    const dispatch = useDispatch()
    const Editshow = useSelector(state => state.info.EditInfo)
    
    // const [readCategory, setReadCategory] = useState([])
    const [code, setCode] = useState(info.target_prices)
    const [a, setA] = useState(Object.keys(code).length)
    const [ symbol , setSymbol ] = useState(info.symbol)
    const [ end , setEnd ] = useState(info.high)
    const [ start , setStart ] = useState(info.low)
    let category



    // useEffect(() => {
        
    //     fetch('http://65.21.30.187:3000/read_category')
    //         .then(res => {
    //             if (res.ok) {
    //                 return res.json()
    //             }
    //             throw res
    //         }).then(data => {
    //             setReadCategory(data)
    //         })


    // }, []);


    const editHandler = async () => {


        try {

            const sendInfo = await fetch('http://65.21.30.187:3000/add_strategy', {
                method: "POST",
                headers: { "Content-Type": "application/json" },

                body: JSON.stringify([{
                    id : info.id,
                    symbol,
                    high: parseFloat(end),
                    low: parseFloat(start),
                    target_prices: info.target_prices,
                    category : info.category,
                    type: "sell",
                    coe: Number(info.coe)
                }])

            })
            const resInfo = await sendInfo.json();
            if (resInfo.message == "strategy saved") {
                alert("ويرايش موفقيت آميز بوده")
                console.log(resInfo)
                
            } else {
                alert(" ويرايش موفقيت آميز نبود")
            }


        } catch (error) {

        }
        
    }

    return (
        <div className='MODALINFO'>
            <div className='MODALINFO-content'>
                <div className="mt-5 bg-white MODALINFO-body">
                    <Row className='p-4'>

                        <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                            <div className='d-flex flex-column align-items-start my-2 '>
                                <label className='text-end font-fa mb-2 fw-700'>نماد</label>
                                <input
                                    className='w-100 p-2  outline-none rounded '  onChange={(e)=>{setSymbol(e.target.value);}} type="text" value={symbol} />
                            </div>
                        </Col>

                        <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                            <div className='d-flex flex-column align-items-start my-2 '>
                                <label className='text-end font-fa mb-2 fw-700'>Low</label>
                                <input
                                    className='w-100 p-2  outline-none rounded ' onChange={(e)=>{setStart(e.target.value);}} type="number" value={start} />
                            </div>
                        </Col>

                        <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                            <div className='d-flex justify-content-center align-items-start flex-column align-items-start my-2 '>
                                <label className='text-end font-fa mb-2 fw-700' >High</label>
                                <input
                                    className='w-100 p-2 outline-none rounded' onChange={(e)=>{setEnd(e.target.value)}} value={end} type="number" />
                            </div>
                        </Col>
                        {/* <Col className="w-full py-4" sm={12} md={12} sm={12} lg={6} xl={6} >
                            <Dropdown className="w-100 border-1 mt-3 btn-block">

                                <Dropdown.Toggle className="text-white p-2 w-100 font-fa" variant="info" id="dropdown-basic">
                                    كانال ها
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {readCategory.data && readCategory.data.map((item, index) =>
                                        <Dropdown.Item  key={index} onClick={() => { category=String(item); console.log(category); }}>{item}</Dropdown.Item>

                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                        </Col> */}
                        <Col sm={12} md={12} sm={12} lg={12} xl={6} >
                            <div className='d-flex flex-column align-items-start my-2 '>
                                <label className='text-end mb-2 fw-600 font-fa' >اهداف</label>
                                <input value={a} onInput={(e) => { setA(e.target.value) }} className='w-100 p-2 rounded outline-none' type="number" />
                            </div>
                            {
                                [...Array(Number(a))].map((item, index) => (

                                    <input value={item} key={index} onInput={({ target }) => {
                                        setCode((p) => {
                                            const newValue = cloneDeep(p);
                                            newValue[index + 1] = parseFloat(target.value)
                                            return newValue;
                                        })
                                    }} className='w-100 p-2 my-2 outline-none' type="number" />
                                )
                                )
                            }



                            {/* {
                               [...Array(Number(a))].map((item , index)=>(
                              
                                    <input key={index} value={code[index] ?? ''} onChange={({target})=>{
                                     setCode((p)=>{
                                            const newValue = cloneDeep(p);
                                            newValue[index]=parseFloat(target.value)
                                            return newValue;
                                        })
                                    }}  className='w-100 p-2 my-2 outline-none' type="number"  />
                                )
                               )
                           } */}
                        </Col>
                        <Col className="my-3" sm={12} md={12} sm={12} lg={12} xl={12}>
                            <div className="">
                                <Button onClick={editHandler} className="  fw-600 font-fa  w-100 p-2 my-2 " variant="outline-info">  ذخيره</Button>
                                <Button onClick={()=>{dispatch(infoAction.changeEdit())}} className="  fw-600 font-fa  w-100 p-2 " variant="outline-danger">بستن</Button>
                            </div>
                        </Col>

                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ModalInfo