import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useRoutes,
} from "react-router-dom";
import GetInformation from './pages/getInformation/GetInformation';
import Report from "./pages/report/Report"
import Layout from './layout/landing/Layout'
import './sass/index.scss'
import Category from './pages/Category/Category';


const routes = [
  // {
  //   path:"/getInformation",
  //   element : <GetInformation/>
  // }
  {
    path: "/",
    element: <Layout />,
    children: [
      { path : '/getInformation' , element : <GetInformation/>  },
      { path : '/reports' , element : <Report/> },
      { path : '/category' , element : <Category/> },
    ],
  },
]

function App() {
  const elements = useRoutes(routes);
  return elements
}

export default App;
