import React , {useState} from 'react';

import { Link } from 'react-router-dom';
import {Button} from "react-bootstrap"
import { Outlet } from 'react-router-dom';
import './Layout.scss'
import { BsChevronDoubleLeft , BsChevronDoubleRight } from "react-icons/bs";
import { FcInspection , FcDataRecovery , FcOpenedFolder } from "react-icons/fc";




const Layout = () => {
    // const [ show , setShow ] = useState(true)

    // const handleSide = ()=>{
    //     setShow(prev => !prev)
    //     if(show){
    //         document.getElementById("sidebar").style.display = "none";
    //         document.getElementById("sideBtn").style.right = "0px";

    //     }else{
    //         document.getElementById("sidebar").style.display = "block";
    //         document.getElementById("sideBtn").style.right = "230px";
    //     }
    // }
    return(
        <div className="LANDING">
            
                <div className="w-100 HEADER">
                    {/* <Header/> */}
                    <div className='w-100 p-1 d-flex justify-content-start align-items-center bg-bluee'>
                        <div className="mx-2">
                            <a href='/'>
                                <img className='marvelLogo' src="/assets/logo.png"  alt="marvel Logo" />
                            </a>
                            
                        </div>
                        {/* <div>
                            <Button className='mx-2' variant='light'>ورود</Button>
                        </div> */}
                    </div>
                </div>
                <div className='w-100' >
                        <div className='sidebar shadow' id="sidebar"> 
                            <div className=' d-flex justify-content-center align-items-center h-100 bg-white'>
                                <ul className='listStyle-none p-1 my-4 w-100'>
                                    <Link className='decoration-none ' to='/reports'>
                                        <li key="report" title='گزارشات' className='sideLi d-flex'>
                                            <span className=' fs-25'> <FcInspection/> </span>
                                            {/* <span className='fs-15'>گزارشات</span> */}
                                        </li>
                                    </Link>

                                    <Link  className='decoration-none' to="/getInformation">
                                        <li key="information" title='تحلیل ها' className=' sideLi d-flex  my-2'>
                                            <span className=' fs-25'> <FcDataRecovery/> </span>
                                            {/* <span  className='fs-15'>دريافت اطلاعات</span> */}
                                        </li>
                                    </Link>

                                    <Link  className='decoration-none' to="/category">
                                        <li key="category"  title='کانال ها' className='d-flex sideLi border-1 my-2'>
                                            <span className=' fs-25'> <FcOpenedFolder/> </span>
                                            {/* <span  className='fs-15'>دسته بندي</span> */}
                                        </li>
                                    </Link>
                                    
                                </ul>
                            </div>
                        </div>
        
                    <div className='outlet'>
                        <Outlet/>
                    </div>
                </div>


        </div>
    )
}

export default Layout;
