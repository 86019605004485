import React from 'react';

import './Category.scss'
import {FcPlus , FcFullTrash} from 'react-icons/fc'
import { Container , Row , Col} from "react-bootstrap"
import useInput from '../../Hooks/useInput';


function Category() {

  const {
    value : category,
    isValid : entercategorytIsValid,
    hasError : categoryInputHasError,
    valueChangeHandler :  categoryChangeHandler,
    inputBlurHandler : categoryBlureHandler,
    reset : resetcategory
} = useInput(value => value.lenght > 0)

const {
  value : delCat,
  isValid : enterdelCattIsValid,
  hasError : categorydelCatInputHasError,
  valueChangeHandler :  delCatChangeHandler,
  inputBlurHandler : delCatBlureHandler,
  reset : resetdelCat
} = useInput(value => value.lenght > 0)

  const addCategory = async () => {

    try{
    const sendInfo = await fetch('http://65.21.30.187:3000/add_category',{
        method : "POST",
        headers : {"Content-Type": "application/json"},
        body: JSON.stringify({
            category
        })

    })
    const resInfo = await sendInfo.json();
    console.log(resInfo);

    if(resInfo.message == "category added"){
      resetcategory()
      alert("دسته بندي با موفقيت افزوده شد")
    }else{
      alert("دسته بندي با موفقيت افزوده نشد")
    }
    
  }catch(error){}
  
}

  const deleteCategory = async () => {
    try{
      const sendInfo = await fetch('http://65.21.30.187:3000/delete_category',{
          method : "POST",
          headers : {"Content-Type": "application/json"},
          body: JSON.stringify({
              category_name : delCat
          })
  
      })
      const resInfo = await sendInfo.json();
      console.log(resInfo);
  
      if(resInfo.data == "category deleted" ){
        
        alert("دسته بندي با موفقيت حذف شد")
        resetdelCat()
      }else{
        alert("دسته بندي با موفقيت حذف نشد")
      }
      
    }catch(error){}
  }


  return(
    <Container fluid className="CATEGORY">
            <Row className='d-flex justify-content-center'>
                <Col xs={10} sm={10} md={10} lg={6} xl={6}>
                    <div className="w-100 h-auto p-4 my-3 bg-white rounded shadow position-relative">
                        <div className='category-title px-2 bg-green rounded'>
                            <p className="text-white fw-700 mt-1 font-fa">كانال</p>
                        </div>
                        <Row className="mt-4">
                            <Col className="my-3"  xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className='d-flex w-100 flex-column align-items-start justify-content-md-between flex-md-row  my-2 '>
                                  <label className='text-end my-2 fw-600 font-fa'> كانال</label>
                                  <input value={category} onChange={categoryChangeHandler} className='w-75 p-2   outline-none rounded ' type="text"  />
                              </div>
                            </Col>
                            <Col  className="my-4  d-flex justify-content-center "  xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <button onClick={addCategory}  className='w-50 mx-auto rounded categoryBtn outline-none p-2 font-fa  fw-700'>
                                        اضافه كردن
                                        <span className="mx-2 my-2  fw-800">
                                           <FcPlus/> 
                                        </span>
                                    </button>
                            </Col>
                        </Row>
                    </div>
                </Col>

                 
            </Row>
            <Row  className='d-flex justify-content-center my-4'>
            <Col xs={10} sm={10} md={10} lg={6} xl={6}>
                    <div className="w-100 h-auto p-4 my-3 bg-white rounded shadow position-relative">
                        <div className='category-title px-2 bg-danger rounded'>
                            <p className="text-white fw-700 mt-1 font-fa">حذف كانال</p>
                        </div>
                        <Row className="mt-4">
                            <Col className="my-3"  xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className='d-flex w-100 flex-column align-items-start justify-content-md-between flex-md-row  my-2 '>
                                  <label className='text-end my-2 fw-600 font-fa'> كانال</label>
                                  <input value={delCat} onChange={delCatChangeHandler} className='w-75 p-2   outline-none rounded ' type="text"  />
                              </div>
                            </Col>
                            <Col  className="my-4  d-flex justify-content-center "  xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <button onClick={deleteCategory}  className='w-50 mx-auto rounded categoryBtn outline-none p-2 font-fa  fw-700'>
                                         حذف كانال
                                        <span className="mx-2 my-2  fw-800">
                                           <FcFullTrash/> 
                                        </span>
                                    </button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
  );
}

export default Category;
