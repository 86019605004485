import { configureStore } from "@reduxjs/toolkit";

import {infoSlice} from "./EditInfo/infoSlice";

const store = configureStore({
    reducer : {
        info : infoSlice.reducer
    }
})

export default store;