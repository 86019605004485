import React, { useState, useEffect } from 'react';


import { Container, Row, Col, Button, Dropdown, Tabs , Tab } from "react-bootstrap"
import useInput from '../../Hooks/useInput';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from "lodash"
import './GetInformation.scss'
import { BsConeStriped, BsSearch } from 'react-icons/bs'
import EditInformation from './EditInformation/EditInformation';

const GetInformation = () => {
    const [key, setKey] = useState('home');
    const [code, setCode] = useState({})
    const [category, setCategory] = useState("")
    const [a, setA] = useState(3)
    const [symbol, setSymbol] = useState("")
    const [symboll, setSymbols] = useState([])
    const [readCategory, setReadCategory] = useState([])
    const [search, setSearchValue] = useState("")
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const UUid = uuid();
    const id = UUid.slice(0, 8)

    useEffect(() => {
        fetch('http://65.21.30.187:3000/symbols')
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                throw res
            }).then(data => {
                setSymbols(data)
            })


        fetch('http://65.21.30.187:3000/read_category')
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                throw res
            }).then(data => {
                setReadCategory(data)
            })


    }, []);


    // const {
    //     value : start,
    //     isValid : enterstartIsValid,
    //     hasError : startInputHasError,
    //     valueChangeHandler :  startChangeHandler,
    //     inputBlurHandler : startBlureHandler,
    //     reset : resetstart
    // } = useInput(value => value.lenght > 0)


    const {
        value: coe,
        isValid: coeIsValid,
        hasError: coeInputHasError,
        setValue: coeSetValue,
        valueChangeHandler: coeChangeHandler,
        inputBlurHandler: coeBlureHandler,
        reset: resetcoe
    } = useInput(value => value.lenght > 0)

    const submitHandler = async () => {


        try {

            const sendInfo = await fetch('http://65.21.30.187:3000/add_strategy', {
                method: "POST",
                headers: { "Content-Type": "application/json" },

                body: JSON.stringify([{
                    id,
                    symbol,
                    high: parseFloat(end),
                    low: parseFloat(start),
                    target_prices: code,
                    category,
                    type: "sell",
                    coe: Number(coe)
                }])

            })
            const resInfo = await sendInfo.json();



            if (resInfo.message == "strategy saved") {
                alert("ارسال موفقيت آميز بوده")
            } else {
                alert("ارسال اطلاعات موفقيت آميز نبود")
            }


        } catch (error) {

        }
        // resetend()
        // resetstart()
        setStart("")
        setEnd("")
        setSearchValue("")
        setCode({})
        resetcoe()
    }

    let formSumbit = true
    if (code) {
        formSumbit = false
    }
    return (
        <Container fluid className='GETINFORMATION'>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 TABS"
            >
                <Tab eventKey="home" title="افزودن">
                    <Row className='d-flex justify-content-center'>
                        <Col xs={10} sm={10} md={8} lg={8} xl={8} className='my-3'>
                            <form onSubmit={submitHandler} className='w-100 h-auto p-4 bg-white rounded shadow-lg'>
                                <div className='bg-bluee text-white py-1 mb-3 mx-auto rounded  w-50'>
                                    <p className='text-center fw-800 font-fa mt-1 fs-20 '>لطفا اطلاعات خود را وارد كنيد</p>
                                </div>
                                <Row>
                                    <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                                        <div className='d-flex flex-column align-items-start my-2 '>
                                            <label className='text-end mb-2 fw-600 font-fa' > سيمبل  (Symbol)</label>
                                            <div className='w-100  border rounded d-flex '>
                                                <span className=' p-2 border bg-gray rounded'><BsSearch /></span>
                                                <input value={search} onChange={e => setSearchValue(e.target.value)} className='w-100 p-2 border-0  outline-none rounded' type="text" />

                                            </div>
                                        </div>
                                    </Col>

                                    <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                                        <div className='mt-4 w-100 resultSearch rounded p-2 bg-light-blue '>
                                            <ul className="d-flex flex-wrap listStlye-none">
                                                {symboll.data && symboll.data
                                                    .filter(name => name.match(new RegExp(search, "i")))
                                                    .map((name, index) => {
                                                        return (<Col className='p-2' xs={6} sm={6} md={4} lg={4} xl={4} ><li key={index}><button type='button' onClick={() => { setSymbol(name); console.log(name); console.log(symbol); setSearchValue(name) }} className="m-2 btnCrypto fs-10 p-2 shadow-md rounded w-100 text-white border-0 p-1 outline-none" >{name}</button> </li></Col>)
                                                    })}
                                            </ul>
                                        </div>
                                    </Col>

                                    <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                                        <div className='d-flex flex-column align-items-start my-2 '>
                                            <label className='text-end mb-2 fw-700'>Low</label>
                                            <input value={start}
                                                onChange={e => {
                                                    setStart(e.target.value)


                                                }}
                                                className='w-100 p-2  outline-none rounded ' type="number" />
                                        </div>
                                    </Col>

                                    <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                                        <div className='d-flex justify-content-center align-items-start flex-column align-items-start my-2 '>
                                            <label className='text-end mb-2 fw-700'>High</label>
                                            <input value={end}
                                                onChange={e => {
                                                    setEnd(e.target.value)

                                                }}
                                                className='w-100 p-2 outline-none rounded' type="number" />
                                        </div>
                                    </Col>

                                    <Col className="w-full py-4" sm={12} md={12} sm={12} lg={6} xl={6} >
                                        {/* <div className='d-flex flex-column align-items-end my-2 '>
                                <label className='text-end mb-2 fw-700'>دسته بندي</label>
                                <input  value={category} onChange={categoryChangeHandler} onBlur={categoryBlureHandler} className='w-100 p-2  outline-none' type="text"  />
                            </div> */}
                                        <Dropdown className="w-100 border-1 mt-3 btn-block">

                                            <Dropdown.Toggle className="text-white p-2 w-100 font-fa" variant="info" id="dropdown-basic">
                                                كانال ها
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {readCategory.data && readCategory.data.map((item, index) =>
                                                    <Dropdown.Item key={index} onClick={() => { setCategory(String(item)); console.log(category); }}>{item}</Dropdown.Item>

                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </Col>

                                    <Col sm={12} md={12} sm={12} lg={6} xl={6} >
                                        <div className='d-flex flex-column align-items-start my-2 '>
                                            <label className='text-end mb-2 fw-700'>ضريب</label>
                                            <input value={coe}
                                                onChange={coeChangeHandler}
                                                className='w-100 p-2  outline-none rounded ' type="number" />
                                        </div>
                                    </Col>

                                    <Col sm={12} md={12} sm={12} lg={12} xl={6} >
                                        <div className='d-flex flex-column align-items-start my-2 '>
                                            <label className='text-end mb-2 fw-600 font-fa' >اهداف</label>
                                            <input value={a} onInput={(e) => { setA(e.target.value) }} className='w-100 p-2 rounded outline-none' type="number" />
                                        </div>
                                        {
                                            [...Array(Number(a))].map((item, index) => (

                                                <input key={index} onInput={({ target }) => {
                                                    setCode((p) => {
                                                        const newValue = cloneDeep(p);
                                                        newValue[index + 1] = parseFloat(target.value)
                                                        return newValue;
                                                    })
                                                }} className='w-100 p-2 my-2 outline-none' type="number" />
                                            )
                                            )
                                        }



                                        {/* {
                               [...Array(Number(a))].map((item , index)=>(
                              
                                    <input key={index} value={code[index] ?? ''} onChange={({target})=>{
                                     setCode((p)=>{
                                            const newValue = cloneDeep(p);
                                            newValue[index]=parseFloat(target.value)
                                            return newValue;
                                        })
                                    }}  className='w-100 p-2 my-2 outline-none' type="number"  />
                                )
                               )
                           } */}
                                    </Col>
                                    <Col sm={12} md={12} sm={12} lg={12} xl={12} className='my-3'>
                                        <div className='w-100'>
                                            <Button className="font-fa" onClick={() => {

                                                if (!end) {
                                                    console.log("end is not trueeeeeee")
                                                    let endd = start
                                                    console.log(endd)
                                                    setEnd(endd)
                                                    console.log(end)

                                                } else if (!start) {
                                                    console.log("start is not trueeeeeee")
                                                    setStart(end)
                                                }
                                                submitHandler();
                                            }} disabled={formSumbit} variant="outline-primary w-100">ثبت اطلاعات</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="edit" title="ويرايش">
                    <EditInformation/>
                </Tab>

            </Tabs>

        </Container>
    )
}
export default GetInformation;
