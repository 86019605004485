import React , {useState , useEffect} from 'react';

import './Report.scss'
import {Container , Row , Col , Dropdown  , Button} from 'react-bootstrap'
import { BsDownload } from 'react-icons/bs';


const Report = () => {
    const [ category , setCategory ] = useState("")

    const [ datee , setDate ] = useState([])
    const [ readCategory , setReadCategory ] = useState([])

    useEffect(() => {
       
        fetch('http://65.21.30.187:3000/read_category')
            .then(res => {
            if(res.ok){
                return res.json()
            }
            throw res
        }).then(data =>{
            console.log(data);
            setReadCategory(data)
            console.log(readCategory)
            
        })


  } , []);

    const download = () => {
       fetch("http://65.21.30.187:3000/download", {
            method : "POST",
            header : {"Content-Type" : "application/json"},
            body: JSON.stringify({
                category : category,
                type : null,
                date_period : datee
            })
        }).then(response =>response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "report.csv";
            document.body.appendChild(a); 
            a.click();    
            a.remove();  
        });

        setDate([])
        
    }

    const startHandler = (enter) => {
        const date = new Date(enter)
        const getDate=""
        const a = getDate.concat(date.toISOString().split('T')[0] , " " , String(date.toISOString().split('T')[1]).slice(0,8))
        // datee.push(String(a))
        setDate(oldArray => [...oldArray, String(a)])
    }



    const endHandler = (enter) => {
        const date = new Date(enter)
        const getDate=""
        const a = getDate.concat(date.toISOString().split('T')[0] , " " , String(date.toISOString().split('T')[1]).slice(0,8))
        // datee.push(String(a))
        setDate(oldArray => [...oldArray, String(a)])
        console.log(datee);

    }
    return(
        <Container fluid className="Report">
            <Row className='d-flex justify-content-center'>
                <Col xs={10} sm={10} md={10} lg={6} xl={6}>
                    <div className="w-100 h-auto p-4 my-3 bg-white rounded shadow position-relative">
                        <div className='report-title px-2 bg-green rounded'>
                            <p className="text-white fw-700 font-fa mt-3">گزارش</p>
                        </div>

                        <Row className="mt-4">
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <p className='fw-600 font-fa fs-15 mx-3'>از تاريخ</p>
                                <input onChange={(e)=>{startHandler(e.target.value)}} type="date"   className='p-3 rounded-pill w-100 bg-gray border-0 outline-none shadow-md' />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <p className='fw-600 font-fa fs-15 mx-3'>به تاريخ</p>
                                <input  onChange={(e)=>{endHandler(e.target.value)}} type="date" className='p-3 rounded-pill w-100 shadow-md border-0 bg-gray '  />
                            </Col>
                            <Col  className="my-3" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Dropdown   className="w-100 border-1 p-2  mt-2 btn-block">
                                    
                                    <Dropdown.Toggle className="text-white p-2 w-100  font-fa" variant="info" id="dropdown-basic">
                                        كانال ها
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {readCategory.data && readCategory.data.map((item , index)=>
                                            <Dropdown.Item key={index} onClick={()=>{setCategory(String(item));console.log(category);}}>{item}</Dropdown.Item>

                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            
                            <Col  className="my-4  d-flex justify-content-center "  xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <button onClick={download} className='w-50 mx-auto rounded download-btn border-0 outline-none p-2 text-white fw-700 font-fa'>
                                        دانلود
                                        <span className="mx-2 my-2  fw-800">
                                           <BsDownload/> 
                                        </span>
                                    </button>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Report;
